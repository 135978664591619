import $ from "jquery";
import Swiper from "swiper/bundle";

const home = {};

home.heroSlider = function () {
  const swiper = new Swiper(".hero-slider .swiper", {
    slidesPerView: 4,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".hero-slider .swiper-button-next",
      prevEl: ".hero-slider .swiper-button-prev",
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
      767: {
        slidesPerView: 3,
        centeredSlides: false,
      },
      0: {
        spaceBetween: 30,
        slidesPerView: 1.4,
        centeredSlides: true,
      },
    },
  });
};

home.messageSlider = function () {
  const swiperTop = new Swiper(".message-slider .message-slider-top", {
    slidesPerView: 5,
    centeredSlides: true,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 3,
    speed: 500,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".message-slider .swiper-button-next",
      prevEl: ".message-slider .swiper-button-prev",
    },
    breakpoints: {
      767: {
        slidesPerView: 5,
      },
      0: {
        slidesPerView: 2.5,
      },
    },
    on: {
      slideChange: function () {
        let classStart = document.querySelectorAll(
          ".message-slider-top .swiper-slide-start"
        );
        let classEnd = document.querySelectorAll(
          ".message-slider-top .swiper-slide-end"
        );

        classStart.forEach((element) => {
          element.classList.remove("swiper-slide-start");
        });
        classEnd.forEach((element) => {
          element.classList.remove("swiper-slide-end");
        });

        let start = this.realIndex - 2;
        let end = this.realIndex + 2;
        let index = this.slides.length - 1;

        if (start < 0) {
          start = start + this.slides.length;
        }

        if (end > index) {
          end = end - this.slides.length;
        }

        let elemStart = document.querySelectorAll(
          '.message-slider-top [data-swiper-slide-index="' + start + '"]'
        );
        let elemEnd = document.querySelectorAll(
          '.message-slider-top [data-swiper-slide-index="' + end + '"]'
        );

        elemStart.forEach((element) => {
          element.classList.add("swiper-slide-start");
        });
        elemEnd.forEach((element) => {
          element.classList.add("swiper-slide-end");
        });
      },
    },
  });

  const swiperBottom = new Swiper(".message-slider .message-slider-bottom", {
    slidesPerView: 5,
    centeredSlides: true,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    loop: true,
    loopedSlides: 3,
    speed: 500,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".message-slider .swiper-button-next",
      prevEl: ".message-slider .swiper-button-prev",
    },
    breakpoints: {
      767: {
        slidesPerView: 5,
      },
      0: {
        slidesPerView: 2.5,
      },
    },
    on: {
      slideChange: function () {
        let classStart = document.querySelectorAll(
          ".message-slider-bottom .swiper-slide-start"
        );
        let classEnd = document.querySelectorAll(
          ".message-slider-bottom .swiper-slide-end"
        );

        classStart.forEach((element) => {
          element.classList.remove("swiper-slide-start");
        });
        classEnd.forEach((element) => {
          element.classList.remove("swiper-slide-end");
        });

        let start = this.realIndex - 2;
        let end = this.realIndex + 2;
        let index = this.slides.length - 1;

        if (start < 0) {
          start = start + this.slides.length;
        }

        if (end > index) {
          end = end - this.slides.length;
        }

        let elemStart = document.querySelectorAll(
          '.message-slider-bottom [data-swiper-slide-index="' + start + '"]'
        );
        let elemEnd = document.querySelectorAll(
          '.message-slider-bottom [data-swiper-slide-index="' + end + '"]'
        );

        elemStart.forEach((element) => {
          element.classList.add("swiper-slide-start");
        });
        elemEnd.forEach((element) => {
          element.classList.add("swiper-slide-end");
        });
      },
    },
  });

  swiperTop.controller.control = swiperBottom;
  swiperBottom.controller.control = swiperTop;
};

home.storySlider = function () {
  const swiper = new Swiper("#popup-story .swiper", {
    slidesPerView: 1,
    pagination: {
      el: "#popup-story .swiper-pagination",
      clickable: true,
    },
  });
};

home.acneSlider = function () {
  const swiper = new Swiper("#popup-acne .swiper", {
    slidesPerView: 1,
    pagination: {
      el: "#popup-acne .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: "#popup-acne .swiper-button-next",
      prevEl: "#popup-acne .swiper-button-prev",
    },
  });
};

home.init = function () {
  this.heroSlider();

  this.storySlider();
  this.acneSlider();

  if (document.readyState !== "loading") {
    this.messageSlider();
  } else {
    document.addEventListener("DOMContentLoaded", function () {
      this.messageSlider();
    });
  }
};

home.init();
